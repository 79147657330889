import React from "react"
import * as Select from "@radix-ui/react-select";
import { useEffect, useState } from "react";
import { Arrow } from "../SVGs/Arrow";

type OptionsType = {
  id: string;
  label: string;
};

type SelectType = {
  options: OptionsType[];
  backgroundColor?: string;
  defaultValue?: string;
  onChange: (id: number | string) => void;
  className?: string;
};

export function SelectComponent({
  options,
  backgroundColor,
  onChange,
  defaultValue,
}: SelectType): JSX.Element {
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    if (onChange) onChange(newValue);

    setIsOpen(false);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue(options?.[0]?.id);
    }
  }, [defaultValue, options]);

  const classesTrigerOpen =
    "border-solid border-[1.5px] border-forest-900 !bg-white rounded-full text-left";

  const classesArrowOpen = "!transform !rotate-0";

  return (
    <div style={{ position: "relative" }}>
      <Select.Root
        value={value}
        onValueChange={handleValueChange}
        open={isOpen}
        onOpenChange={handleOpenChange}
      >
        <Select.Trigger
          className={`relative w-[335px] bg-[${backgroundColor}] flex items-center outline-none rounded-full text-left h-[44px] p-4 font-semibold text-base	${isOpen && classesTrigerOpen}`}
        >
          <Select.Value placeholder="" className="teste99">
            {options.find((item) => item.id === value)?.label}
          </Select.Value>
          <Select.Icon>
            <Arrow
              className={`absolute right-5 w-5 top-[17px] transform rotate-180 ${isOpen && classesArrowOpen}`}
            />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content className="outline-none text-base font-semibold text-forest-900 p-4 rounded-lg bg-white mt-2 border-[1.5px] border-forest-200 w-[335px]">
          {options?.map((item) => (
            <Select.Item
              key={item.id}
              value={item.id}
              className="pb-6 text-forest-900 text-base font-semibold leading-none cursor-pointer outline-none last:pb-0"
            >
              <Select.ItemText>{item?.label}</Select.ItemText>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </div>
  );
}

export default SelectComponent;
